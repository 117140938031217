import { Component, Input, SimpleChanges } from '@angular/core';
import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import { environment } from '../../../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-event-status',
  templateUrl: './event-status.component.html',
  styleUrls: ['./event-status.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule],
})
export class EventStatusComponent {
  @Input() primaryEventGroup!: string;
  @Input() protestManagementProcess?: boolean;
  @Input() scoreboardStatus!: string;
  @Input() status!: string;

  text = '';
  statusClass = '';
  icon!: IconProp;

  ngOnInit() {
    this.updateStatus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes['status'] && !changes['status'].isFirstChange()) ||
      (changes['scoreboardStatus'] && !changes['scoreboardStatus'].isFirstChange())
    ) {
      this.updateStatus();
    }
  }

  updateStatus() {
    if (this.scoreboardStatus && this.status !== 'r' && this.status !== 'un' && this.status !== 'ca') {
      this.status = this.scoreboardStatus;
    }

    switch (this.status) {
      case 'ca': // Cancelled
        this.icon = 'close';
        this.text = $localize`:Short status message to say the event is cancelled@@eventStatusCancelled:Cancelled`;
        this.statusClass = 'status-cancelled';
        break;
      case 'ci': // Checking In
        this.icon = 'door-open';
        this.text = $localize`:Short status message to say the event is available for check in@@eventStatusCheckIn:Check In`;
        //"Multi Table Tab Title on the Event page@@event.multiTableTabTitle"
        // `:meaning|description@@id:source message text`;
        this.statusClass = 'status-checking-in';
        break;
      case 'cc': // Check In Done
        this.icon = 'door-closed';
        this.text = $localize`:Short status message to say that check in for the event is closed@@eventStatusClosedCheckIn:Closed`;
        this.statusClass = 'status-check-in-done';
        break;
      case 'e': // Entries
        this.icon = 'chevron-right';
        this.text = $localize`:Short status message to say the event has entries@@eventStatusEntries:Entries`;
        this.statusClass = 'status-entries';
        break;
      case 'eh': // Entries and Heats
        this.icon = 'chevron-right';
        this.text =
          this.primaryEventGroup === 'Field'
            ? $localize`:Short status message for field events to say the event has seeded flights @@eventStatusFlights:Flights`
            : $localize`:Short status message for track events to say the event has seeded heat sheets @@eventStatusHeatSheets:Heats`;
        this.statusClass = 'status-entries-heats';
        break;
      case 'h': // Heats
        this.icon = 'chevron-right';
        this.primaryEventGroup === 'Field'
          ? $localize`:Short status message for field events to say the event has seeded flights @@eventStatusFlights:Flights`
          : $localize`:Short status message for track events to say the event has seeded heat sheets @@eventStatusHeatSheets:Heats`;
        this.statusClass = 'status-heats';
        break;
      case 'nd': // No data. No entries, heats, or results
        this.icon = 'chevron-right';
        this.text = $localize`:Short status message to say the event is scheduled. This is the default if no other data is available.@@eventStatusScheduled:Scheduled`;
        this.statusClass = 'status-no-data';
        break;

      case 'r': // Final results
        this.icon = 'check';
        this.text = this.protestManagementProcess
          ? $localize`:Short status message to say the event has final and official results@@eventStatusOfficial:Official`
          : $localize`:Short status message to say the event has final results@@eventStatusResults:Results`;
        this.statusClass = 'status-results';
        break;
      case 'hr': // Heat results, but not final results
        this.icon = 'ellipsis-h';
        this.text = $localize`:Short status message to say the event is in progress. This appears if the event has heat by heat results uploaded, but is not showing real time results@@eventStatusInProgress:In Progress`;
        this.statusClass = 'status-heat-results';
        break;
      case 'l': // Live. In progress with scoreboard connection
        this.icon = environment.icons.live;
        this.text = $localize`:Short status message to say the event is live right now@@eventStatusLive:Live`;
        // if (runTime) {
        //   this.text = runTime;
        // }
        this.statusClass = 'status-live';
        break;
      case 'nl': // No longer live. Waiting state between live results and final results
        this.icon = 'check';
        this.text = $localize`:Short status message to say the event has finished real time results, but no final results have been uploaded@@eventStatusDone:Done`;
        this.statusClass = 'status-no-longer-live';
        break;
      case 'un': // Unofficial. Only used when a timer uses protest management
        this.icon = 'check';
        this.text = $localize`:Short status message to say the event is finished but only has unofficial results@@eventStatusUnofficial:Unofficial`;
        this.statusClass = 'status-no-longer-live';
        break;
      case 'up': // Event is under protest
        this.icon = 'exclamation-triangle';
        this.text = $localize`:Short status message to say the event is under protest@@eventStatusUnderProtest:Under Protest`;
        this.statusClass = 'status-under-protest';
        break;
      case 'ur': // Event is under review
        this.icon = 'eye';
        this.text = $localize`:Short status message to say the event is under review@@eventStatusUnderReview:Under Review`;
        this.statusClass = 'status-under-review';
        break;
      case 'pd': // Event is under review
        this.icon = 'ellipsis-h';
        this.text = $localize`:Short status message to say the event is pending@@eventStatusPending:Pending`;
        this.statusClass = 'status-pending';
        break;
      case 'pf': // Event is under review
        this.icon = 'ellipsis-h';
        this.text = $localize`:Short status message to say the event is waiting for the final@@eventStatusPendingFinal:Pending Final`;
        this.statusClass = 'status-pending-final';
        break;
      case 'wu': // Warming Up
        this.icon = 'ellipsis-h';
        this.text = $localize`:Short status message to say the event is warming up@@eventStatusWarmingUp:Warming Up`;
        this.statusClass = 'status-warming-up';
        break;
      default:
        this.icon = 'calendar';
        this.text = $localize`:Short status message to say the event is scheduled. This is the default if no other data is available.@@eventStatusScheduled:Scheduled`;
        break;
    }
  }
}
